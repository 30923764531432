import Fireworks from "react-canvas-confetti/dist/presets/fireworks";
import './App.css';

function App() {
  return (
  <>
    <Fireworks autorun={{ speed: 2 }} />
    <div style={{
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      }}>
        <div style={{
          display: 'flex',
          flexFlow: 'row nowrap',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <img style={{
           minHeight: '50vh',
           maxHeight: '100vh',
          }} src={ "/no.png" }/>
        </div>
      </div>
  </>
  );
}

export default App;
